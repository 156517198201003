import * as React from "react";
import styles from './SvgComponent.module.css';

function SvgComponent(props) {
  return (
    <div className={styles.logo}>
      ResumeMatchPro
    </div>
  );
}

export default SvgComponent;
